import { useApi } from '~/api';
import { getFirstQueryParam } from '~/utils/common';
import { useRoute } from '#imports';
import { components } from '~~/api-types';

export enum ContentMetricType {
  VIEW = 'view',
  LINKEDIN_SHARE = 'linkedin-share',
  FACEBOOK_SHARE = 'facebook-share',
  TWITTER_SHARE = 'twitter-share',
  ACTION_BUTTON = 'action-button',
  LINKEDIN_POST = 'linkedin-post',
  TWITTER_POST = 'twitter-post',
  FACEBOOK_POST = 'facebook-post',
  INSTAGRAM_POST = 'instagram-post',
  FILE_DOWNLOAD = 'file-download',
  FACEBOOK_POST_DOWNLOAD = 'facebook-post-download',
  INSTAGRAM_POST_DOWNLOAD = 'instagram-post-download',
  TWITTER_POST_DOWNLOAD = 'twitter-post-download',
  LINKEDIN_POST_DOWNLOAD = 'linkedin-post-download',
  SENT_LINK_COPIED = 'sent-link-copied',
  SENT_EMAIL = 'sent-email',
}

export enum LaunchMetricType {
  OPENED = 'opened',
  COMPLETED = 'completed',
  NOT_STARTED = 'not-started',
}

export type CreateContentMetricBodyDto =
  components['schemas']['CreateContentMetricBodyDto'];

export const createContentMetric = (
  hubSlug: string,
  contentSlug: string,
  metricType: ContentMetricType,
) => {
  const api = useApi();
  const route = useRoute();

  const requestBody: CreateContentMetricBodyDto = {
    metricType,
  };
  const advocateCampaignId = Number(getFirstQueryParam(route.query.adc));
  if (Number.isInteger(advocateCampaignId) && advocateCampaignId > 0) {
    requestBody.advocateCampaignId = advocateCampaignId;
  }

  return api(`/app/hubs/${hubSlug}/contents/${contentSlug}/metrics`, {
    method: 'POST',
    body: requestBody,
  });
};

export const createContentLaunchMetric = (
  hubSlug: string,
  contentSlug: string,
  metricType: LaunchMetricType,
) => {
  const api = useApi();

  return api(`/app/hubs/${hubSlug}/contents/${contentSlug}/launch-metrics`, {
    method: 'POST',
    body: {
      metricType,
    },
  });
};
